<script>
import { Line, mixins } from 'vue-chartjs'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips'
import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities'

export default {
  components: {
    hexToRgba,
    CustomTooltips
  },
  extends: Line,
  mixins: [mixins.reactiveData],
  data() {
    return {
      chartData: '',
      range: '',
      errors: {
        code: '',
        status: '',
        headers: ''
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData)
  },
  methods: {
    postTransaction(start, end, target) {

      this.$http.post(`show-target-transaction`,{
        start_from: start,
        end_to: end,
        target: target
      }).then((response) => {
        const male = response.data.Buyying.male;
        const female = response.data.Buyying.female;
        // const unknown = response.data.Buyying.unknown;
        const date = response.data.days;
        this.chartData = {
          labels: date,
          datasets: [
            {
              label: 'Male',
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
              data: male
            },
            {
              label: 'Female',
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              data: female
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: true,
          tooltips: {
            enabled: false,
            custom: CustomTooltips,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
              labelColor: function (tooltipItem, chart) {
                return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
              }
            }
          }
        }
      }).catch((error) => {
        if (error.response) {
          this.errors.status = error.response.data.meta.code;
          this.errors.code = error.response.status;
          this.errors.headers = error.response.headers;
        }
      })
    }
  },
  created () {

    this.$http.get(`show-target-transaction`)
    .then((response) => {
      const male = response.data.Buyying.male;
      const female = response.data.Buyying.female;
      // const unknown = response.data.Buyying.unknown;
      const date = response.data.days;
      this.chartData = {
        labels: date,
        datasets: [
          {
            label: 'Male',
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
            data: male
          },
          {
            label: 'Female',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
            data: female
          },
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false,
          custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function (tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].backgroundColor }
            }
          }
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
}
</script>
